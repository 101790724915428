import React from 'react';
import getinfoLogo from '../assets/images/logo.svg';
import collaboratorTitle from '../assets/images/title.svg';
import { CollaboratorLoginForm } from '../components/collaborator/CollaboratorLoginForm';
import { useAuth } from '../contexts/AuthContext';
import { Redirect } from 'react-router-dom';

const EspacoColaborador = () => {
  const { currentUser } = useAuth();

  // If user is already logged in, redirect to dashboard
  if (currentUser) {
    return <Redirect to='/espaco-colaborador-dashboard' />;
  }

  return (
    <div className='espaco-colaborador'>
      <div className='collaborator-login-wrapper'>
        <img src={getinfoLogo} alt='Logo da Getinfo' />
        <img src={collaboratorTitle} alt='Título da área de colaborador' />
        <CollaboratorLoginForm />
      </div>
    </div>
  );
};

export default EspacoColaborador;
