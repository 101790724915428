import React, { Component } from 'react';
import { i18n } from '../translate/i18n.js';
import { Button } from 'bloomer';
import SimpleReactValidator from 'simple-react-validator';
import { CheckCircleIcon, CloseCircleIcon, CubeSendIcon } from 'mdi-react';
import Recaptcha from 'react-recaptcha';
import MaskedInput from 'react-maskedinput';

// CSS
import './../assets/styles/views/contato.scss';

class Contato extends Component {
  constructor(props) {
    super(props);

    this.verifyCallback = this.verifyCallback.bind(this);

    this.state = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      answer: '',
      isValidRecaptcha: false,
    };

    this.validator = new SimpleReactValidator({
      messages: {
        default: i18n.t('contato.campoObrigatorio'),
      },
    });
  }

  onLoadCallback() {
    // console.log('Recaptcha carregado!');
  }

  verifyCallback() {
    this.setState({ isValidRecaptcha: true });
  }

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ answer: '' });

    if (!this.state.isValidRecaptcha) {
      this.setState({
        answer: [<CloseCircleIcon key='1' />, i18n.t('contato.recaptcha')],
      });
      return;
    }

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    this.setState({
      answer: [<CubeSendIcon key='2' />, 'Enviando sua mensagem...'],
    });

    fetch('https://getinfomail.herokuapp.com/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dest: 'contato@getinfo.net.br',
        sub: 'Solicitação de contato - Getinfo',
        html: `
                    <p><strong>Nome: </strong>
                     ${this.state.name}</p>
                     <p><strong>E-mail: </strong>
                     ${this.state.email}</p>
                     <p><strong>Telefone: </strong>
                     ${this.state.phone}</p>
                     <p><strong>Assunto: </strong>
                     ${this.state.subject}</p>
                     <p><strong>Mensagem: </strong>
                     ${this.state.message}</p>`,
      }),
    })
      .then(() => {
        this.setState({
          answer: [
            <CheckCircleIcon key='3' />,
            'Sua mensagem foi enviada com sucesso!',
          ],
        });
      })
      .catch((_error) => {
        this.setState({
          answer: [
            <CloseCircleIcon key='4' />,
            'Ops! Ocorreu algum erro ao enviar sua mensagem. Por favor, tente novamente.',
          ],
        });
      });
  };

  render() {
    const { onLoadCallback, verifyCallback } = this;

    return (
      <div id='contato'>
        <div className='section'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-5 box animated fadeInLeft'>
                <h1>{i18n.t('contato.envienoumamensagem')}</h1>
                <form onSubmit={this.handleSubmit}>
                  <div className='field'>
                    <label className='label'>{i18n.t('contato.nome')}</label>
                    <div className='control'>
                      <input
                        name='name'
                        type='text'
                        className='input'
                        placeholder={i18n.t('contato.placeName')}
                        value={this.state.name}
                        onChange={this.handleFormChange}
                      />
                      <span>
                        {this.validator.message(
                          'name',
                          this.state.name,
                          'required'
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label'>{i18n.t('contato.email')}</label>
                    <div className='control'>
                      <input
                        name='email'
                        type='email'
                        className='input'
                        placeholder={i18n.t('contato.placeEmail')}
                        value={this.state.email}
                        onChange={this.handleFormChange}
                      />
                      <span>
                        {this.validator.message(
                          'email',
                          this.state.email,
                          'required'
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label'>
                      {i18n.t('contato.telefone')}
                    </label>
                    <div className='control'>
                      <MaskedInput
                        name='phone'
                        type='text'
                        className='input'
                        placeholder={i18n.t('contato.placePhone')}
                        mask='(11) 11111-1111'
                        size='13'
                        value={this.state.phone}
                        onChange={this.handleFormChange}
                      />
                      <span>
                        {this.validator.message(
                          'phone',
                          this.state.phone,
                          'required'
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label'>{i18n.t('contato.assunto')}</label>
                    <div className='control'>
                      <input
                        name='subject'
                        type='text'
                        className='input'
                        placeholder={i18n.t('contato.placeSubject')}
                        value={this.state.subject}
                        onChange={this.handleFormChange}
                      />
                      <span>
                        {this.validator.message(
                          'subject',
                          this.state.subject,
                          'required'
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='field'>
                    <label className='label'>
                      {i18n.t('contato.mensagem')}
                    </label>
                    <div className='control'>
                      <textarea
                        name='message'
                        className='textarea'
                        placeholder={i18n.t('contato.placeMessage')}
                        value={this.state.message}
                        onChange={this.handleFormChange}
                      ></textarea>
                      <span>
                        {this.validator.message(
                          'message',
                          this.state.message,
                          'required'
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='recaptcha'>
                    <Recaptcha
                      sitekey='6LdbZGIUAAAAAIr-e4TTQ2r96Fy2va3yn2sBof7E'
                      render='explicit'
                      onloadCallback={onLoadCallback}
                      verifyCallback={verifyCallback}
                      hl='pt-BR'
                      required
                    />
                  </div>
                  <div className='answer'>{this.state.answer}</div>
                  <div className='field'>
                    <div className='control'>
                      <Button type='submit'>
                        {i18n.t('contato.sendButton')}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>

              <div className='column is-6 is-offset-1 como-chegar animated fadeInRight'>
                <h1>{i18n.t('contato.comoChegar')}</h1>

                <div className='columns'>
                  <div className='column is-one-quarter'>
                    <p className='title no-break'>
                      {i18n.t('contato.endereco')}
                    </p>
                  </div>
                  <div className='column'>
                    <p className='description'>
                      SergipeTec
                      <br />
                      Av. José Conrado de Araujo, 731, Bairro Rosa Elze,
                      SergipeTec - Bloco B3 - Sala 06
                      <br />
                      CEP 49107-232 | São Cristóvão - SE
                    </p>
                  </div>
                </div>
                <div className='columns'>
                  <div className='column is-one-quarter'>
                    <p className='title no-break'>
                      {i18n.t('contato.telefone')}
                    </p>
                  </div>
                  <div className='column'>
                    <p className='description'>
                      {i18n.t('contato.telephoneNumber')}
                    </p>
                  </div>
                </div>
                <div className='columns'>
                  <div className='column is-one-quarter'>
                    <p className='title no-break'>E-mail:</p>
                  </div>
                  <div className='column'>
                    <a
                      className='description'
                      href='mailto:contato@getinfo.net.br'
                    >
                      contato@getinfo.net.br
                    </a>
                  </div>
                </div>
                <hr />
                <div className='columns' style={{ marginTop: '30px' }}>
                  <div
                    className='column gmaps'
                    style={{
                      overflow: 'hidden',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <iframe
                      className='mapa'
                      title='Getinfo'
                      width='600'
                      height='450'
                      id='map'
                      frameBorder='0'
                      loading='lazy'
                      allowfullscreen
                      style={{ border: 'none', marginTop: 70 }}
                      src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJBQAAALAmq3ERzmZvZGv64-0&key=AIzaSyD9_5DpNVvIzfGLfSuMoKk6Uo7HBVNEsSQ'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contato;
