import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './styles/index.css'; // Import your CSS file here

export const CollaboratorLoginForm = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
  });

  const onSubmit = async (data) => {
    try {
      setError('');
      setLoading(true);

      // For demo purposes, we'll just check for a simple credential
      // In a real app, you would validate against an API
      if (data.username === 'admin' && data.password === 'admin123') {
        login({
          username: data.username,
          role: 'admin',
        });

        // Redirect user to the dashboard or the page they were trying to access
        const { from } = location.state || {
          from: { pathname: '/espaco-colaborador-dashboard' },
        };
        history.replace(from);
      } else {
        setError('Usuário ou senha incorretos');
      }
    } catch (err) {
      setError('Falha ao realizar login. Por favor, tente novamente.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='login-form-container'>
      <form onSubmit={handleSubmit(onSubmit)} className='login-form'>
        <h1 className='login-form-title'>Faça login na sua conta</h1>

        {error && <div className='error-message'>{error}</div>}

        <div className='login-form-inputs'>
          <div className='form-group'>
            <label htmlFor='username'>Usuário</label>
            <input
              required
              id='username'
              className='form-control'
              {...register('username', { required: 'Usuário é obrigatório' })}
            />
            {errors.username && (
              <span style={{ color: 'red', fontSize: '12px' }}>
                {errors.username.message}
              </span>
            )}
          </div>

          <div className='form-group'>
            <label htmlFor='password'>Senha</label>
            <input
              required
              type='password'
              id='password'
              className='form-control'
              {...register('password', { required: 'Senha é obrigatória' })}
            />
            {errors.password && (
              <span style={{ color: 'red', fontSize: '12px' }}>
                {errors.password.message}
              </span>
            )}
          </div>
        </div>

        <div className='form-check'>
          <input
            type='checkbox'
            id='rememberMe'
            className='form-check-input'
            {...register('rememberMe')}
          />
          <label htmlFor='rememberMe' className='form-check-label'>
            Lembrar de mim?
          </label>
        </div>

        <div className='form-actions'>
          <button type='submit' className='btn-submit' disabled={loading}>
            {loading ? 'Entrando...' : 'Entrar'}
          </button>
          <a className='forgot-me' href='#'>
            Esqueceu sua senha?
          </a>
        </div>
      </form>
    </div>
  );
};
