export const text = {
  en: {
    translations: {
      banner: {
        voceTemaIdeia: 'You have the idea,',
        nosTemosaSolucao: 'we have the solution.',
      },
      menu: {
        servicos: {
          title: 'Services',
          desenvolvimentoDeSoftwares: 'Software Development',
          bussinessInteligence: 'Business Intelligence',
          governanca: ' IT Governance',
          ti: 'IT',
        },
        treinamentos: 'Courses',
        trabalheConosco: 'Work with us',
        covid19: 'COVID-19',
        contato: 'Contact us',
      },
      home: {
        desenvolvemosSoftware: 'We develop software,',
        naoImportaAPlataforma: 'no matter the platform,',
        nosEstamosProntos: 'we are ready.',
        saibaMais: 'More',
        getiCursos: 'Getinfo Courses',
        getiTreinamentos: 'Training',
        descricao: `Invest in your carrer and improve your knowledge. Here we have the course you need for your certification.`,
        saibaMais: 'More',
        nossosClintes: 'Our Clients',
        verListaCompleta: 'See full list',
        verListaReduzida: 'See reduced list',
        seuproblema: 'Your problem is our challenge.',
        criadaEm: `Founded in 2009, Getinfo is a company devoted to finding solutions that will help you or your company optimize results.`,
      },
      softwareDeveloper: {
        title: 'Software Development',
        descricao:
          'It is the process of developing a software product, whether for web or mobile. Do you have an idea and want to put it into practice? No matter the platform, we are ready!',
        textButton:
          'If you need to develop projects for web, android or iOS, contact us',
      },
      faleConosco: {
        text: 'Contact Us',
      },
      clubeDeVantagens: {
        title: 'Advantage Club',
        descricao:
          "The Advantage Club platform allows users new experiences in the digital space. Through the website and mobile app it is possible to manage all discounts in a simple and fast way and take advantage of the club's advantages even more",
        emBreve: 'Coming Soon',
      },
      clinicaCas: {
        title: 'CAS Clinic',
        descricao:
          'Platform developed for the Anesthesia Clinic of Salvador, in order to streamline the internal processes in the clinic and have all data from the surgeries documented digitally.',
      },
      cartorioAminthas: {
        title: 'Notary Public Aminthas Garcez',
        descricao:
          'The Notary Aminthas Garcez website has all the services that the Notary offers. In addition to allowing the user to carry out registration, signature and duplicate notice queries. The website is responsive and adapts to screens in any device, whether desktop or mobile.',
        saibaMais: 'Read More',
        verProjeto: 'See the full project at:',
        outrosProjetos: 'Other projects',
      },
      pajucara: {
        title: 'PajuPLUS',
        descricao: `The PajuPlus brings together all the multiplatform content of the Pajuçara Communication System and is the largest digital content provider in Alagoas. The app brings together information and entertainment, with interactive and real-time content and was natively developed for iOS and Android systems.`,
        saibaMais: 'read more',
        veiculosDeComunicacao: {
          title: 'Communication Vehicles',
          descricao:
            'Channels of communication of information via Radio and TV, following high ethical and credibility standards.',
        },
        tvPajucara: {
          title: 'Pajuçara TV',
          descricao:
            'With your smartphone, you can watch a whole selection of channels available on TV Pajuçara.',
        },
        radios: {
          title: 'Arapiraca Radio',
          radioarapiracadescricao:
            'Listen to Pajuçara FM Arapiraca 101.9 station Maceió Radio.',
          avaliacoes: 'Maceió Radio',
          avaliacoesdescricao: 'Listen to Pajuçara FM Maceió 103.7 station.',
        },
        vocereporter: {
          title: 'Be a reporter',
          descricao:
            'Instant and easy channel for users to collaborate with Pscom via smartphone.',
        },
        portalDeNoticias: {
          title: 'News Portal',
          descricao:
            'Most accessed news portal in Alagoas, information following high ethics and credibility standards.',
        },
      },
      pionet: {
        title: 'PioNet',
        descricao: `Always looking to advance in terms of technology, Pio Décimo College makes PioNET Mobile available for Android and iOS. This app provides college students with access to: grades and absences, schedules, notices and pending issues.`,
        saibaMais: 'Read More',
        app: 'PioNet App',
        appDescricao:
          'Pio Décimo college students now have an app to view schedules, grades, absences, pending issues and notices.',
        horario: 'Schedule',
        horarioDescricao:
          'It is possible to view the entire grid of the student’s current period. Informing the days and times when the student has a certain class.',
        notasefaltas: 'Notes and Absences',
        notasefaltasDescricao:
          "With the smartphone, it is possible to know the absences and grades of the student's current period.",
        pendencias: 'Pendencies',
        pendenciasDescricao:
          'It is possible to check if there is any pending on the part of the student with the institution.',
        avisos: 'Notices',
        avisosDescricao:
          'As soon as the teacher posts a notice, the student receives a notification. Quickly and conveniently, without complications.',
      },
      getiTreinamentos: {
        title: 'Getinfo Courses',
        descricao:
          'Getinfo Training makes you remember your studied content through targeted questionnaires. Simulate tests of important certifications, break your head over remembering the subjects of your professional day to day or learn which subjects are necessary to deepen the knowledge you want.',
        saibaMais: 'Read More',
        crieumaconta: 'Create an account and save your progress.',
        crieumacontadescricao: `Through your email or your Facebook account, register and have access to your simulation history, thus monitoring your progress.`,
        controleotempo: 'Control the tme',
        controleotempodescricao: `The simulations present situations similar to the original tests. Time your time and identify the issues you have the most difficulty with.`,
        simuladoItil: 'ITIL Foundation mock available!',
        simuladoItildescricao: `ITIL Foundation is one of the most important certification tests in the Information Technology area, covering knowledge about strategy, design, operations and service development.`,
        baixeagora: 'Download now and test your knowledge.',
        baixeGratuito: 'Download for Free',
      },
      footer: {
        servicos: {
          title: 'Services',
          desenvolvimentoDeSoftware: 'Software Development',
          bussinessInteligence: 'Business Intelligence (BI)',
          governanca: 'IT Governance',
          thinkAgile: 'Thinking Agile (Agile Management)',
          treinamentos: 'Courses',
          contato: 'Contact us',
          sede: 'Headquarters',
        },
      },
      404: {
        paginanaoencontrada: 'Page not found...',
        paginanaoexiste:
          'A página solicitada não existe ou está em construção.',
        voltaraoinicio: 'Voltar para a página inicial',
      },
      blog: {
        carregandonoticias: 'Carregando notícias...',
        semnoticias: 'Nenhuma notícia publicada até o momento.',
      },
      blogPost: {
        carregandonoticias: 'Carregando notícias...',
        naofoipossivelcarregarasnoticias:
          'Não foi possível carregar a notícia, certifique que a URL está correta',
      },
      bussiness: {
        servicos: {
          title: 'Business Intelligence',
          descricao: `The Qlik Business Discovery platform is a user-driven BI solution that enables fast and intelligent decision making. It includes doing things like`,
          desenvolvimentoDeIndicadores:
            'Development of key performance indicators (KPIs);',
          analiseDeTendencias: 'Trend analysis;',
          modelagemPreditiva: 'Predictive Modeling;',
          paineis: 'Custom Panels.',
          visaoGeraldaFerramenta: 'Tool overview',
        },
        // Kpi Dashboard
        imagem: {
          title: 'Accurate and time-saving decisions',
          text: `Effective decision making is based on having the right information available and easily accessible. Taking just a few minutes to learn, Qlik's automatic joins create endless possibilities for making queries without requiring tedious structures and hierarchies, as is typical in other data analysis tools.`,
        },
        // Sales analisys
        imagem2: {
          title: 'Understand how data is linked',
          text: `Qlik enables users to gain unexpected business insights by understanding how data is linked and which data is unrelated`,
        },
        // Interfaces de Usuario
        imagem3: {
          title: 'Simple and straightforward user interfaces',
          text: `It brings a new level of analysis, insight and value to existing data stores with user interfaces that are clean, simple and straightforward.`,
        },
        //Integrações com todas as bases de dados
        imagem4: {
          title: 'Integration with all databases',
          text: `Unify data sources for a complete view of information, without gaps, and make it easier to uncover hidden insights.`,
        },
        servico: {
          casosdesucesso: 'Success Cases',
          banese: `Application that allows managers to have a detailed view of the profile of their customers and also to analyze trends related to their behavior. In addition, it is possible to monitor the transactions carried out in the service channels, as well as to evaluate indicators of the credit portfolio, such as the number of existing contracts, amounts settled, among others`,
          pscom: `Panel designed to monitor the general situation of sales of advertising spaces of vehicles of the Pajuçara group, as well as to evaluate the performance of the sales team`,
          progresso: `MKBF (Mean Kilometer Between Failure) - Application for monitoring the number of kilometers that a car can travel until it fails, in addition to showing the lines on which buses break more, which drivers give more tickets to the workshops.
`,
          progressoPt2: `Monthly revenue forecast – Panel that issues a projection of monthly revenue based on the days of the month that already have the data.`,
          faleConosco: {
            textButton:
              'If you would like to have this solution, please contact us for more details:',
          },
        },
      },
      governanca: {
        title: 'IT Governance',
        descricao:
          'We are able to promote and guarantee information security, service availability and business continuity, providing services based on the best market practices.',
        destaqueSeusNegocios: 'Highlight your business',
        destaqueDescricao: `If you are looking to highlight your business and get ahead in the business race, bet on IT tools. They help in the management and control process, in addition to guaranteeing the quality of the services and products offered.`,
        aumenteACompetitividade: 'Increase the competitiveness of your company',
        competitividadeDescricao: `Now it's even easier to devise strategies to increase your competitiveness: Through a set of practices that use software and systems that suit your organization's objectives and rules. Get to know how IT Governance can be used in your company and achieve excellent results for your business.`,
        suporteTecnico: 'Specialized technical support',
        suporteTecnicoDescricao: `We propose complete and customized solutions for telephony, data storage, management and monitoring of the park of servers and stations. We have specialized technical support, with Infrastructure monitoring and Support By Report.`,
        textButton:
          'If you would like to have this solution, please contact us for more details:',
      },
      treinamentos: {
        title: 'Training Courses',
        descricao: `Invest in your career and improve your knowledge, come to the Getinfo team. Here we have the training you need to take your certification.`,
        agile: {
          title: 'Training course - THINKING AGILE (AGILE MANAGEMENT)',
          descricao:
            'Do you already use an Agile Management model to design your projects in short iterations, continuous deliveries with a cross-functional team? If your answer is no, Get_info can help.',
          saibaMais: 'Know More',
        },
        aplicativo: {
          title: 'Discover the Courses app',
          saibaMais: 'Know More',
        },
        treinamentoAgile: {
          title: 'Course - THINKING AGILE (AGILE MANAGEMENT)',
          descricao: `Do you already use an Agile Management model to design your projects in short iterations, continuous deliveries with a cross-functional team? If your answer is no, Get_info can help.`,
          dataTurma:
            'Due to the COVID 19 pandemic we are adapting to the online version of this course and will publish the new dates soon.',
          investimento:
            'R$690.00 / R$600.00 (Boleto/Pix) / 10% discount (Credit Card in one installment) / Discount for Groups.',
          horario: '8h – 12h e 14h – 18h.',
          instrutorNome: 'Adriana Fontes',
          instrutorDescricao: `Adriana Fontes holds a Master's degree in Computer Science from the Federal University of Sergipe, an MBA in Project Management from the Faculty of Business Administration of Sergipe (FANESE) and specializations in Software Project Management from FANESE and Didactics of Higher Education from the Associação de Ensino e Cultura Pio Décimo. Bachelor's Degree in Information Systems at Universidade Tiradentes and a Computer Technician with Emphasis in Systems Development at Colégio Pio Décimo.She is a Certified Scrum Professional-ScrumMaster, Certified Scrum Professional-Product Owner and Certified Lean Kanban by LeanKanban University. She has held the positions and roles of Systems Programmer, Systems Analyst, Product Owner and Scrum Master in several projects at Associação de Ensino e Cultura Pio Décimo for almost 10 years. Coordinated and taught several subjects in the undergraduate courses in Pedagogy and Technician in Computer Science at the University and Colégio Pio Décimo. She taught the Bachelor's Degree in Information Systems at the Instituto Federal de Sergipe-Campus Lagarto. Coordinated the Cyber ​​Defense Technology course at Faculdade UNIRB - Campus Aracaju. Worked as Consultant, Project Manager and Scrum Master at Trust Consultoria e Softwares for 8 years. In 2019 she did 8 months of exchange in Canada where she studied English at the International Language Academy of Canada in Toronto. And, Currently, she works as a Consultant at the company Get_Info Soluções Corporativas.`,
          objetivo: `Our Thinking Agile training includes Design Thinking techniques and methods to help you materialize your idea and then the Scrum Framework added to the concept of Squads to develop and market your product or service.`,
          publicoAlvo: 'Students, managers and professionals in general.',
          conteudoProgramatico: `Agile Culture and Agile Project Management.\nAgile Manifesto, Principles and Values ​​of the Agile Manifesto.\nFramework Scrum:\nTime   Scrum   - Scrum Team - Product Owner, Scrum Master, Development Team.\nScrum Events - Sprint, Sprint Planning, Daily Scrum, Sprint Review, Sprint Retrospective, Release.\nScrum Artifacts - Product Backlog, Sprint Backlog, Definition of Done, Product Increment, Business Goals, Work Tracking Charts, Definition of Done.\nMaterializing the Product Vision.\nDesign Thinking Techniques and Methods.\nSquads, Tribes, Chapter.\nScrum Alliance certifications.\nTraining with different dynamics and practices that aim to increase participant engagement and content assimilation.`,
          datasETurmasTexto: 'Date and Classes',
          investimentoTexto: 'Investment',
          horarioTexto: 'Time',
          informacoesTexto: 'Informations',
          contatoTexto: 'Get in touch via email',
          instrutorTexto: 'Instructor',
          objetivoTexto: 'Goal',
          preRequisitoTexto: 'Prerequisite',
          publicoAlvoTexto: 'Target Audience',
          conteudoProgramaticoTexto: 'Content ',
          inscrevaSeTexto: 'SIGN UP',
          inscricoesEncerradasTexto: 'Enrollments Closed',
        },
      },
      trabalheConosco: {
        envieSeuCurriculum: 'Send your resume',
        preenchaSeusDados: 'Fill in your details below',
        nome: 'Name *',
        area: 'Área *',
        email: 'E-mail *',
        selecioneUmaArea: 'Select an área',
        administrativo: 'Administrative',
        comercial: 'Commercial',
        desenvolvimento: 'Development',
        estagio: 'Internship',
        suporte: 'Support',
        celular: 'Telephone *',
        selecioneOArquivo: 'Select the File',
        enviar: 'Send',
      },
      contato: {
        campoObrigatorio: 'Required',
        recaptcha: 'Please check captcha.',
        envienoumamensagem: 'Send us a message',
        nome: 'Name',
        placeName: 'Type your name',
        email: 'E-mail',
        placeEmail: 'Type your Email',
        placePhone: '+99 (99)99999-9999',
        assunto: 'Subject',
        placeSubject: 'Type the subject',
        mensagem: 'Message',
        placeMessage: 'Type the message',
        sendButton: 'Send',
        endereco: 'Address:',
        telefone: 'Phone:',
        telephoneNumber: ' +55 (79) 3022-9001',
        comoChegar: 'How to reach us:',
      },
    },
  },
};
