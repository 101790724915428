import React from 'react';
import { i18n } from '../../translate/i18n';
import { Columns, Column } from 'bloomer';
import { NavLink } from 'react-router-dom';

const Footer = () => (
  <div id='footer' className='animated fadeIn'>
    <div className='section'>
      <div className='container'>
        <Columns>
          <Column>
            <h1>{i18n.t('footer.servicos.title')}</h1>
            <ul>
              <li>
                <NavLink to='/servicos/desenvolvimento'>
                  {i18n.t('footer.servicos.desenvolvimentoDeSoftware')}
                </NavLink>
              </li>
              <li>
                <NavLink to='/servicos/business'>
                  {i18n.t('footer.servicos.bussinessInteligence')}
                </NavLink>
              </li>
              <li>
                <NavLink to='/servicos/governanca'>
                  {i18n.t('footer.servicos.governanca')}
                </NavLink>
              </li>
            </ul>
          </Column>
          <Column>
            <h1>{i18n.t('footer.servicos.treinamentos')}</h1>
            <ul>
              <li>
                <NavLink to='/treinamentos/agile'>
                  {i18n.t('footer.servicos.thinkAgile')}
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/treinamentos/android">Android - Módulo 1</NavLink>
              </li>
              <li>
                <NavLink to="/treinamentos/iso-20000">ISO 20000</NavLink>
              </li>
              <li>
                <NavLink to="/treinamentos/iso-27002">ISO 27002</NavLink>
              </li>
              <li>
                <NavLink to="/treinamentos/itil">ITIL Foundation</NavLink>
              </li> */}
            </ul>
          </Column>
          <Column>
            <h1>{i18n.t('footer.servicos.contato')}</h1>
            <ul>
              <li>
                <p>
                  <strong>{i18n.t('footer.servicos.sede')}</strong>
                  <br />
                  Rua Zaqueu Brandão, 226, Bairro São José
                  <br />
                  CEP 49015-330 | Aracaju - SE
                  <br />
                  <br />
                  <strong>SergipeTec</strong>
                  <br /> Av. José Conrado de Araujo, 731, Bairro Rosa Elze,
                  SergipeTec - Bloco B3 - Sala 06
                  <br />
                  CEP 49107-232 | São Cristóvão - SE
                </p>
              </li>
              <li>
                <p>
                  <strong>{i18n.t('footer.telefone')}</strong>
                  <br />
                  {i18n.t('footer.numeroTelefonico')}
                </p>
              </li>
              <li>
                <a href='mailto:contato@getinfo.net.br'>
                  contato@getinfo.net.br
                </a>
              </li>
            </ul>
          </Column>
        </Columns>
      </div>
    </div>
    <hr />
    <div className='section'>
      <div className='container'>
        <Columns className='final-footer'>
          <Column>
            <p className='center'>{i18n.t()}</p>
          </Column>
        </Columns>
      </div>
    </div>
  </div>
);

export default Footer;
