import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import logo from '../assets/images/logo-getinfo-1.png';

const EspacoColaboradorDashboard = () => {
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  useEffect(() => {
    // Configure Chatling
    window.chtlConfig = { chatbotId: '7662247876' };

    // Create and load the script
    const script = document.createElement('script');
    script.async = true;
    script.dataset.id = '7662247876';
    script.id = 'chatling-embed-script';
    script.type = 'text/javascript';
    script.src = 'https://chatling.ai/js/embed.js';

    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      removeChatScript();
    };
  }, []);

  const removeChatScript = () => {
    const script = document.getElementById('chatling-embed-script');
    if (script) {
      document.body.removeChild(script);
    }

    // Also remove any chatbot UI elements that might have been created
    const chatElements = document.querySelectorAll('[id^="chatling"]');
    chatElements.forEach((element) => {
      element.remove();
    });
  };

  const handleLogout = () => {
    // Remove the chat script before logging out
    removeChatScript();

    logout();
    history.push('/espaco-colaborador');
  };

  return (
    <div className='espaco-colaborador-dashboard'>
      <header className='header'>
        <div className='header-content'>
          <div className='header-text'>
            <h1>Espaço do Colaborador</h1>
            <p>
              Bem-vindo(a), {currentUser?.username}! Aqui você encontra tudo o
              que precisa: comunicados, benefícios, eventos, oportunidades e
              muito mais para o seu dia a dia na Getinfo.
            </p>
          </div>
          <div className='header-logo'>
            <img src={logo} alt='Getinfo' className='logo-getinfo' />
          </div>
        </div>
      </header>

      <div className='dashboard-content'>
        <div className='dashboard-sidebar'>
          <h2>Menu</h2>
          <ul>
            <li>
              <a href='#comunicados'>Comunicados</a>
            </li>
            <li>
              <a href='#beneficios'>Benefícios</a>
            </li>
            <li>
              <a href='#documentos'>Documentos</a>
            </li>
            <li>
              <a href='#eventos'>Eventos</a>
            </li>
          </ul>
          <button onClick={handleLogout} className='logout-button'>
            Sair
          </button>
        </div>

        <div className='dashboard-main'>
          <section id='comunicados'>
            <h2>Comunicados</h2>
            <div className='card'>
              <h3>Férias Coletivas</h3>
              <p>
                Informamos que a empresa entrará em férias coletivas entre os
                dias 20/12 e 05/01.
              </p>
              <span className='date'>Publicado em: 15/11/2023</span>
            </div>
          </section>

          <section id='beneficios'>
            <h2>Benefícios</h2>
            <div className='card'>
              <h3>Plano de Saúde</h3>
              <p>
                Confira as informações sobre o novo plano de saúde empresarial.
              </p>
              <button className='btn-details'>Ver detalhes</button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EspacoColaboradorDashboard;
